import { RouteHttpVerb } from ".";
const NOTICE_ID = "notice-corrupt-rest-api"; // eslint-disable-next-line @typescript-eslint/no-empty-function

async function addToNotice(namespace, onlyWhen = async () => {}) {
  const notice = document.getElementById(NOTICE_ID); // Only in backend and when a corrupt REST API detected

  if (notice) {
    // Already shown as "defect"?
    if (notice.querySelector("ul").innerText.indexOf(namespace) > -1) {
      return;
    }

    try {
      await onlyWhen();
    } catch (e) {
      notice.style.display = "block";
      const li = document.createElement("li");
      li.innerHTML = "<code>".concat(namespace, "</code>");
      notice.childNodes[1].appendChild(li);
      notice.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    }
  }
}
/**
 * Set a request as failing so the endpoint is probably corrupt (see `corruptRestApi.tsx`)
 *
 * Please consider the following:
 *
 * > The fetch() function will automatically throw an error for network errors but not for HTTP errors such as 4xx or 5xx responses.
 *
 * @see https://jasonwatmore.com/post/2021/10/09/fetch-error-handling-for-failed-http-responses-and-network-errors
 */


function addCorruptRestApi({
  method
}, addNamespaceImmediate) {
  if (method === RouteHttpVerb.GET) {
    if (addNamespaceImmediate) {
      addToNotice(addNamespaceImmediate, () => {
        throw new Error();
      });
    } else {
      window.detectCorruptRestApiFailed = (window.detectCorruptRestApiFailed || 0) + 1;
      window.dispatchEvent(new CustomEvent(NOTICE_ID));
    }
  }
}
/**
 * Register a new endpoint which needs to resolve to a valid JSON result. In this way we
 * can detect a corrupt REST API namespace e. g. it is blocked through a security plugin.
 */


function handleCorrupRestApi(resolve, forceRerequest = false) {
  // Initially set
  window.detectCorruptRestApiFailed = window.detectCorruptRestApiFailed || 0;

  const fnCheck = async () => {
    // Only in backend and when a corrupt REST API detected
    if (window.detectCorruptRestApiFailed > 0 || forceRerequest) {
      for (const namespace of Object.keys(resolve)) {
        addToNotice(namespace, resolve[namespace]);
      }
    }
  };

  let checkTimeout;

  const fnThrottled = () => {
    clearTimeout(checkTimeout);
    checkTimeout = setTimeout(fnCheck, 1000);
  };

  fnThrottled();
  window.addEventListener(NOTICE_ID, fnThrottled);
}

export { addCorruptRestApi, handleCorrupRestApi };